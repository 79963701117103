const initialState = {
    data: {
        show_menu:false,
        call_to_action: false,
        show_menu_casino:false,
        bet_history_details:false,
        navigation_link:null,
        print_title:'highlights',
        leader_board:null,
        leader_boardx:null,
        active_link:'79',
        stake_value:0,
        active_live_link:'79',
        bottom_sheet:false,
        promo_bottom_sheet:false,
        loading:false,
        withdraw_loading:false,
        deposit_loading:false,
        deposit_confirm_loading:false,
        error:null,
        app_config:null,
        carousel_banners:null,
        points_message:null,
        withdrawal_message:null,
        deposits_message:null,
        deposits_confirm_message:null,
        self_exclsuion_message:null,
        self_exclsuion_time:null,
        show_modal:null,
        show_exclusion_modal:false,
        loaded:false,
        printed_data:null,
        print_jackpot_data:null,

    }, // Initial state for the data reducer
    virtuals: {
        favorites_data:[],
        game_type:'pragmatic',
        bottom_sheet:false,
        loading:false,
        error:null,
        casino_games:[],
        casino_search:[],
        show_modal:null,
        casino_categories:null,
        smartsoft_categories:null,
        casino_game_url:null,
        fetching:null

    }, // Initial state for the virtuals reducer
    betting: {
        insufficient_balance:false,
        bet_placement_message:null,
        loading: false,
        loading_slip_validation: false,
        error: null,
        error_slip_validation: null,
        jackpot: null,
        kiron: null,
        picked:"",
        betslip_validation_status:false,
        slip_has_live_interval:false,
        slip:null,
        slip_validation_data:null,
        betslip:null,
        jackpotbestlip:null,
        minStake:null,
        betslipLength:0,
        netWin:0,
        netWinBoosted:0,
        hasBoost:false,
        remaining_games:0,
        multiboostmessage:null,
        multiboostmessagebetslip:null,
        multiboostmessagekiron:null,
        multiboostmessagebetslipkiron:null,
        alert_boost_color:'',
        betslip_options:null,
        kiron_betslip_options:null,
    },// Initial state for the betting Slice reducer
    auth: {
        isLoading: false,
        error: null,
        isLoggedIn: false,
        user: null,
        loading: false,
        user_sign_up:null,
        reset_success: false,
        reset_error: null,
        reset_message: null,
        reset_success_password: null,
        reset_password_error: null,
        reset_password_message: null,
        otp_sent: false,
        reset_mobile:null,
        reset_id:null
    }, // Initial state for the auth reducer
    nareLeague: {
            isLoading: false,
            error: null,
            isLoggedIn: false,
            user: null,
            time_set: false,
            loading: false,
            time_left:null,
            play_time:null,
            periods_data:null,
            isCountDownTimeActive:null,
            current_selection_period:null,
            periods_ready:false,
            inPlay:false,
            matches_data:null,
            playouts_data:null,
            results_data:null,
            standings_data:null,
            competitions_data:null,
            bet_history_data:null,
            bet_details_data:null,
            old_bets_data:null,
            old_bet_details:null,
            current_selection:null,
            round_id:null,
            first_period:null,
            market_id:null,
            competition_id:2,
            active_market:3,
            start_time:null,
            end_time:null,
            markets_data:null,
            market_options:null,
            close_spinner:null,
            start_playouts:null,
            game_week:null,
            ended:null,

        }, // Initial state for the nareLeague reducer
    matchesData:{
        loading: true,
        loading_cashout: false,
        cashout_error:null,
        loading_cashout_confirmation: false,
        cashout_response: null,
        cashout_confirmation: null,
        search:null,
        active_sub_type:'1x2',
        active_sport:'Soccer',
        initialLoading:true,
        isLoading: false,
        searched_matches:null,
        visited_tabs:[],
        visited_sport_id:[],
        visited_sport_league:[],
        jackpot_loading: false,
        error: null,
        user: null,
        visited_filters:[],
        visited_more_markets:[],
        user_slip_validation:null,
        live_user_slip_validation:null,
        producer_down:null,
        live_producer_down:null,
        fetching:null,
        live_fetching:null,
        sport_categories:null,
        loading_top_leagues:false,
        sport_live_count:null,
        favorites_data:[],
        jackpot_data:null,
        jackpot_history:[],
        jackpot_by_id:null,
        market_groups:null,
        more_matches:[],
        matches:null,
        limit: 10,
        bet_cancel:null,
        bet_cancel_status:null,
        bet_can_cancel_status:null,
        full_bet_details:null,
        bet_details:null,
        bet_details_meta:null,
        back_navigation:false,
        live_matches:null,
        bet_cancel_end_time:null,
        share_bet:null,
        rebet_match:null,
        loading_bet_history:null,
        // prev_match_size:0,
        match_size:10,
        live_match_size:0,
        show_share_modal:false,
        show_deposit_modal:false,
        show_h2h_modal:false,
        h2h_modal_matchId:null,
        show_betslip_modal: false,
        betslip_share_choice:null,
        decoded_match: null,
    },// Initial state for the matches Slice reducer
    scroll:{
        position:0,
        scroll: false,
        scroll_past:false,
        scroll_top:false

    },
    navigations:{
        notShowSearch: [
            'casino',
            'nare-league',
            'jackpot',
            'promotion',
            'livescore',
            'smart-play',
            'stp-play',
            'stp-play',
            'nare-games',
            'gameplay',
            'how-to-play',
            'privacy-policy',
            'terms-and-conditions'

        ],
        notShowMobileNav: [
            'betslip-slip',
            'betslip-nare',
            'signup',
            'leader-board',
            'nare-league',
            'results',
            'my-bets',
            'promotions',
            'smart-soft',
            'casino',
            'standing',
            'playouts',
            'bet-history',
            'nare-games',
            'responsible-gambling',
            'promo',
            'smart-play',
            'fazi-play',
            'stp-play',
            'gameplay',
            'fpl',
            'FPL',
            'how-to-play',
            'cookie-policy',
            'privacy-policy',
            'dispute-resolution',
            'anti-money-laundering',
            'terms-and-conditions',
            "profile",
            'jackpot',
            'jiserve'
        ],
        showDownload: [
            'nare-games',
            'promo',
            'bethistory',
            'terms-and-conditions',
            'gameplay',
            'smart-play',
            'fazi-play',
            'stp-play',
            'betslip-slip',
            'betslip-nare',
            'betslip-jackpot',
            'nare-league',
            'bet-history',
            'standing',
            'results',
            'jackpot',
            'smart-soft',
            'virtuals',
            'competition',
            'my-bets',
            'profile',
            'promotions',
            'leader-board',
            'responsible-gambling',
            'match',
            'smart-soft',
            'casino',
            'gameplay',
            'fpl',
            'FPL',
            'how-to-play',
            'privacy-policy',
            'cookie-policy',
            'dispute-resolution',
            'anti-money-laundering',
            'profile'
        ],
        changeMobileNav: [
            'verify',
            'signup',
            'login',
            'deposit',
            'withdraw',
            'points',
            'reset-password',

        ],
        modifiedHeaderDesktop:[
            'profile'
        ],
        notShowHeader:[
            'gameplay', 
            'smart-play',
            'fazi-play',
            'stp-play',
            'nare-games'

        ],
        navigations:[
            'nare-games',
            'promo',
            'bethistory',
            'smart-play',
            'fazi-play',
            'stp-play',
            'betslip-slip',
            'betslip-nare',
            'betslip-jackpot',
            'jackpot',
            'smart-soft',
            'virtuals',
            'competition',
            'my-bets',
            'promotions',
            'leader-board',
            'match',
            'smart-soft',
            'casino',
            'gameplay',
            'fpl',
            'FPL',
            'how-to-play',
            'privacy-policy',
            'cookie-policy',
            'dispute-resolution',
            'anti-money-laundering',
            'terms-and-conditions',
            'responsible-gambling',
            'login',
            'deposit',
            'withdraw',
            'points',
            'reset_password',
            'playouts',
            'nare-league',
            'bet-history',
            'standing',
            'results',
        ],
        top_navigations:[]

    }

};

export default initialState;
